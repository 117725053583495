import { Button } from "~/components/ui/button"
import { DisconnectButton, type AgentState } from "@livekit/components-react";
import { motion, AnimatePresence } from "framer-motion";
import { AGENT_INFO } from "@neverpay/content";
import { AudioLines, Loader2, X, Mic, MicOff } from "lucide-react";
import { useLocalParticipant, useLocalParticipantPermissions, usePersistentUserChoices, useTrackToggle } from "@livekit/components-react";
import { Track } from 'livekit-client';
import type { TrackReferenceOrPlaceholder, CaptureOptionsBySource, ToggleSource } from '@livekit/components-core';
import React from "react";
import * as Sentry from "@sentry/remix";

// Component for the Control Bar
export function ControlBar({ 
  onConnectButtonClicked, 
  agentState 
}: { 
  onConnectButtonClicked: () => void; 
  agentState: AgentState 
}) {
  const isConnecting = agentState === "connecting";
  const localPermissions = useLocalParticipantPermissions();
  const { microphoneTrack, localParticipant } = useLocalParticipant();

  const micTrackRef: TrackReferenceOrPlaceholder = React.useMemo(() => {
    return {
      participant: localParticipant,
      source: Track.Source.Microphone,
      publication: microphoneTrack,
    };
  }, [localParticipant, microphoneTrack]);

  const { saveAudioInputEnabled, saveAudioInputDeviceId } = usePersistentUserChoices({
    preventSave: false,
  });

  const microphoneOnChange = React.useCallback(
    (enabled: boolean, isUserInitiated: boolean) => {
      if (isUserInitiated) {
        saveAudioInputEnabled(enabled);
      }
    },
    [saveAudioInputEnabled],
  );

  const { buttonProps, enabled } = useTrackToggle({
    source: Track.Source.Microphone,
    onChange: microphoneOnChange,
    onDeviceError: (error) => {
      console.error(error);
      // Capture microphone errors with context
      Sentry.captureException(error, {
        tags: {
          errorType: 'microphone_error'
        },
        extra: {
          participantId: localParticipant.identity,
          participantName: localParticipant.name,
          isMicrophoneEnabled: localParticipant.isMicrophoneEnabled,
          isCameraEnabled: localParticipant.isCameraEnabled,
          lastMicrophoneError: localParticipant.lastMicrophoneError,
          lastCameraError: localParticipant.lastCameraError,
          isScreenShareEnabled: localParticipant.isScreenShareEnabled,
        }
      });
    }
  });

  return (
    <div className="fixed bottom-0 left-0 right-0 flex justify-center pb-[calc(env(safe-area-inset-bottom)+1rem)] px-4">
      <AnimatePresence mode="wait">
        {agentState === "disconnected" && (
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 50 }}
            transition={{ duration: 0.3 }}
          >
            <Button
              size="lg"
              onClick={onConnectButtonClicked}
              className="w-full max-w-[200px] mx-auto py-6 rounded-full bg-[#012854] text-white hover:bg-[#023a7a] transition-colors duration-200 disabled:opacity-50 disabled:cursor-not-allowed font-semibold flex items-center justify-between px-4 shadow-lg shimmer"
            >
              <span className="flex-grow text-center">
                {isConnecting ? 'Connecting...' : `Speak with ${AGENT_INFO.SHORT_NAME}`}
              </span>
              {isConnecting ? (
                <Loader2 className="h-6 w-6 ml-2 flex-shrink-0 animate-spin" />
              ) : (
                <AudioLines className="h-6 w-6 ml-2 flex-shrink-0" />
              )}
            </Button>
          </motion.div>
        )}

        {agentState !== "disconnected" && (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 20 }}
            transition={{ duration: 0.3 }}
            className="w-full max-w-md flex justify-between items-center"
          >
            {localPermissions?.canPublish && (
              <>
                <motion.div
                  initial={{ opacity: 0, scale: 0.8 }}
                  animate={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 0.3, delay: 0.3 }}
                >
                  <button
                    {...buttonProps}
                    className={`p-4 rounded-full ${!enabled ? 'bg-red-600 hover:bg-red-700' : 'bg-[#012854] hover:bg-[#023a7a]'} text-white transition-colors duration-200`}
                  >
                    {enabled ? <Mic className="h-6 w-6" /> : <MicOff className="h-6 w-6" />}
                  </button>
                </motion.div>
                {/* <MediaDeviceMenu
                  kind="audioinput"
                  onActiveDeviceChange={(_kind, deviceId) => saveAudioInputDeviceId(deviceId ?? '')}
                  className="hidden"
                /> */}
              </>
            )}
            <DisconnectButton >
              <motion.div
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.3, delay: 0.3 }}
                className="p-4 rounded-full bg-[#012854] text-white hover:bg-[#023a7a] transition-colors duration-200"
              >
                <X className="h-6 w-6" />
              </motion.div>
            </DisconnectButton>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}
