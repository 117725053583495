import type { LoaderFunctionArgs, ActionFunctionArgs } from "@remix-run/cloudflare";
import { useLoaderData, useFetcher } from "@remix-run/react";
import { LiveKitRoomComponent } from "~/components/LiveKitRoom";
import { getOrCreateParticipantId, createConnectionResponse } from "~/lib/livekit.server";
import { json } from "@remix-run/cloudflare";
import type { ConnectionDetails } from "~/types/livekit";

// Define a type for our loader data
type LoaderData = 
  | { connectionDetails?: ConnectionDetails; participantId: string }
  | { error: string };

export async function loader({ request, context }: LoaderFunctionArgs) {
  try {
    const { participantId, headers } = await getOrCreateParticipantId(request, context.cloudflare.env);
    
    return json({ participantId }, {
      headers
    });
  } catch (error) {
    console.error("Loader error:", error);
    return json({ error: "Failed to initialize connection" }, { status: 500 });
  }
}

export async function action({ request, context }: ActionFunctionArgs) {
  try {
    const { participantId } = await getOrCreateParticipantId(request, context.cloudflare.env);

    if (!participantId) {
      return json({ error: "No participant ID found" }, { status: 400 });
    }

    return createConnectionResponse(participantId, context.cloudflare.env);
  } catch (error) {
    console.error("Action error:", error);
    return json({ error: "Failed to refresh connection" }, { status: 500 });
  }
}

type ActionData = {
  connectionDetails: ConnectionDetails;
  participantId: string;
} | {
  error: string;
};

export default function LK() {
  const loaderData = useLoaderData<typeof loader>();
  const fetcher = useFetcher<ActionData>();

  // Handle connection errors
  if ('error' in loaderData) {
    return (
      <div className="min-h-full flex items-center justify-center">
        <p className="text-red-500">Failed to initialize connection</p>
      </div>
    );
  }

  // Get connection details from fetcher data if available
  const connectionDetails = fetcher.data?.connectionDetails;

  return (
    <div className="min-h-full flex flex-col justify-between items-center p-8 pb-[env(safe-area-inset-bottom)] text-[#012854] font-['Montserrat',sans-serif] relative overflow-y-auto box-border">
      <LiveKitRoomComponent 
        conData={connectionDetails}
        onConnectRequest={() => {
          fetcher.submit({}, { method: "post" });
        }}
      />
    </div>
  );
}
