import { useState, useCallback, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import {
  LiveKitRoom,
  useVoiceAssistant,
  BarVisualizer,
  RoomAudioRenderer,
  type AgentState,
} from "@livekit/components-react";
import type { MediaDeviceFailure } from "livekit-client";
import { NoAgentNotification } from "./NoAgentNotification";
import { ControlBar } from "./ControlBar";
import type { ConnectionDetails } from "~/types/livekit";
import { AGENT_INFO, INTRO_TEXTS} from "@neverpay/content";
import { cn } from "~/lib/utils";
import * as Sentry from "@sentry/remix";

import "@livekit/components-styles";

// Move error handling to a separate component or utility
const handleDeviceFailure = (failure?: MediaDeviceFailure) => {
  console.error(failure);
  alert(
    "Error acquiring camera or microphone permissions. Please make sure you grant the necessary permissions in your browser and reload the tab"
  );
};

interface SimpleVoiceAssistantProps {
  onStateChange: (state: AgentState) => void;
  isConnecting: boolean;
}

function SimpleVoiceAssistant({
  onStateChange,
  isConnecting,
}: SimpleVoiceAssistantProps) {
  const { state, audioTrack } = useVoiceAssistant();

  useEffect(() => {
    onStateChange(state);
  }, [onStateChange, state]);

  const shouldShowVisualizer = isConnecting || state !== "disconnected";

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ 
        opacity: shouldShowVisualizer ? 1 : 0,
        y: shouldShowVisualizer ? 0 : 20 
      }}
      exit={{ opacity: 0, y: 20 }}
      transition={{ duration: 0.5 }}
      className={cn(
        "w-full max-w-[360px] h-[200px] mx-auto left-1/2 -translate-x-1/2",
        !shouldShowVisualizer && "pointer-events-none"
      )}
    >
      <BarVisualizer
        state={isConnecting ? "connecting" : state}
        barCount={5}
        trackRef={audioTrack}
        className="agent-visualizer"
        options={{ 
          minHeight: 24,
          maxHeight: 200
        }}
      />
    </motion.div>
  );
}

interface LiveKitRoomComponentProps {
  conData?: ConnectionDetails;
  onConnectRequest: () => void;
}

export function LiveKitRoomComponent({ conData, onConnectRequest }: LiveKitRoomComponentProps) {
  const [connectionDetails, setConnectionDetails] = useState<ConnectionDetails | undefined>(undefined);
  const [agentState, setAgentState] = useState<AgentState>("disconnected");
  const [introText, setIntroText] = useState("");
  const [isConnecting, setIsConnecting] = useState(false);
  const [isConnected, setIsConnected] = useState(false);

  useEffect(() => {
    const randomIndex = Math.floor(Math.random() * INTRO_TEXTS.length);
    setIntroText(INTRO_TEXTS[randomIndex]);
  }, []);

  // Update connectionDetails only when new conData is received
  useEffect(() => {
    if (conData) {
      setConnectionDetails(conData);
    }
  }, [conData]);

  const handleConnectButtonClick = useCallback(() => {
    if (!isConnected && !isConnecting) {
      setIsConnecting(true);
      onConnectRequest();
    }
  }, [onConnectRequest, isConnected, isConnecting]);

  const handleDisconnect = useCallback(() => {
    setConnectionDetails(undefined);
    setIsConnecting(false);
    setIsConnected(false);
    setAgentState("disconnected");
    
    // Clear LiveKit context from Sentry
    Sentry.setContext("livekit", null);
  }, []);

  const handleConnected = useCallback(() => {
    setIsConnecting(false);
    setIsConnected(true);
    
    // Add LiveKit context to Sentry
    if (connectionDetails) {
      Sentry.setContext("livekit", {
        userId: connectionDetails.participantName,
        roomId: connectionDetails.roomName
      });
    }
  }, [connectionDetails]);

  const handleError = (error: Error) => {
    console.error("LiveKit connection error:", error);
    handleDisconnect();
  };
  return (
    <div className="w-full max-w-2xl flex flex-col items-center justify-center flex-grow relative z-10 pb-[calc(env(safe-area-inset-bottom)+10rem)]">
      {/* Logo - Fixed position at top */}
      <motion.div
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.25 }}
        className="w-56 mb-4 md:mb-8 fixed top-0 pt-[calc(env(safe-area-inset-top)+2rem)]"
      >
        <img src="/logo-light.svg" alt="Neverpay Logo" className="w-full" />
      </motion.div>

      {/* Content Container - Fixed height to prevent layout shifts */}
      <div className="relative h-[400px] w-full flex items-center justify-center mt-20">
        {/* Intro Text */}
        <AnimatePresence mode="wait">
          {!connectionDetails && !isConnecting && (
            <motion.div
              key="intro-text"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3 }}
              className="text-2xl md:text-4xl font-light text-center absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-full"
            >
              <span
                dangerouslySetInnerHTML={{
                  __html: introText.replace(
                    AGENT_INFO.SHORT_NAME,
                    `<strong>${AGENT_INFO.SHORT_NAME}</strong>`
                  ),
                }}
              />
            </motion.div>
          )}
        </AnimatePresence>

        {/* LiveKit Room Container */}
        <LiveKitRoom
          token={connectionDetails?.participantToken}
          serverUrl={connectionDetails?.serverUrl}
          connect={!!connectionDetails}
          audio={true}
          video={false}
          onMediaDeviceFailure={handleDeviceFailure}
          onDisconnected={handleDisconnect}
          onConnected={handleConnected}
          onError={handleError}
          className="w-full max-w-md relative"
        >
          <SimpleVoiceAssistant
            onStateChange={setAgentState}
            isConnecting={isConnecting}
          />
          <ControlBar
            onConnectButtonClicked={handleConnectButtonClick}
            agentState={agentState}
          />
          <RoomAudioRenderer />
          <NoAgentNotification state={agentState} />
        </LiveKitRoom>
      </div>
    </div>
  );
}
