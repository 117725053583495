import type { AgentState } from "@livekit/components-react";
import { useEffect, useRef, useState } from "react";
import { Alert, AlertDescription } from "~/components/ui/alert";
import { Button } from "~/components/ui/button";
import { AlertTriangle, X } from "lucide-react";
import * as Sentry from "@sentry/remix";

interface NoAgentNotificationProps {
  state: AgentState;
}

/**
 * Renders some user info when no agent connects to the room after a certain time.
 */
export function NoAgentNotification({ state }: NoAgentNotificationProps) {
  const timeToWaitMs = 10_000;
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const [showNotification, setShowNotification] = useState(false);
  const agentHasConnected = useRef(false);

  // If the agent has connected, we don't need to show the notification.
  if (
    ["listening", "thinking", "speaking"].includes(state) &&
    !agentHasConnected.current
  ) {
    agentHasConnected.current = true;
  }

  useEffect(() => {
    if (state === "connecting") {
      timeoutRef.current = setTimeout(() => {
        if (state === "connecting" && !agentHasConnected.current) {
          setShowNotification(true);
          Sentry.captureMessage("No agent connected after waiting period.", {
            level: 'error',
            extra: {
              state,
              timeToWaitMs,
              message: "Agent connection timeout",
              description: "The agent did not connect within the expected time frame."
            }
          });
        }
      }, timeToWaitMs);
    } else {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      setShowNotification(false);
    }

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [state]);

  if (!showNotification) return null;

  return (
    <Alert variant="destructive" className="fixed top-6 left-1/2 -translate-x-1/2 max-w-[90vw] flex items-center gap-4">
      <AlertTriangle className="h-6 w-6 text-gray-400" />
      <AlertDescription className="text-pretty w-max">
        It&apos;s quiet... too quiet. Is Max lost? Ensure Max's server
        is properly configured and running on your machine.
      </AlertDescription>
      <Button
        variant="ghost"
        size="icon"
        onClick={() => setShowNotification(false)}
        className="ml-auto"
      >
        <X className="h-4 w-4 text-gray-400" />
      </Button>
    </Alert>
  );
}
